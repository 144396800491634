@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono&display=swap');

@font-face {
  font-family: 'IBM Plex Sans VF';
  font-display: fallback;
  src: url('./fonts/IBMPlexSans-VF.woff2') format('woff2-variations'),
    url('./fonts/IBMPlexSans-VF.woff2') format('woff2');
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Sans VF';
  font-display: fallback;
  src: url('./fonts/IBMPlexSans-Italic-VF.woff2') format('woff2-variations'),
    url('./fonts/IBMPlexSans-Italic-VF.woff2') format('woff2');
  font-weight: 100 900;
  font-style: italic;
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-display: fallback;
  src: url('./fonts/IBMPlexSans.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-display: fallback;
  src: url('./fonts/IBMPlexSans-SemiBold.woff');
  font-weight: 600;
}

body {
  font-size: 1em;
  line-height: 1.5;
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
}

@supports (font-variation-settings: normal) {
  body {
    font-family: 'IBM Plex Sans VF', 'Helvetica Neue', Arial, sans-serif;
  }
}