@use '@carbon/react/scss/spacing';
@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/colors';
@use '@carbon/react/scss/config';



.#{config.$prefix}--header {
    background-color: theme.$background-inverse;
}



a.#{config.$prefix}--header__menu-item {
    color: theme.$layer-active;
    background-color: theme.$background-inverse;
}

a.#{config.$prefix}--header__menu-item.active {
    color: theme.$field;
    background-color: theme.$border-subtle; //lighten(theme.$background-inverse-hover, 20%);
}

a.#{config.$prefix}--header__menu-item:hover {
    background-color: theme.$background-inverse-hover;
    color: theme.$field;
}