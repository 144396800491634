
@use "sass:map";


@use '@carbon/react/scss/themes';

@use '@carbon/react/scss/theme' with (
  $theme: map-merge(
    themes.$g10,
    (
      text-on-color: #fff,
      background-brand: #0099bb,
      border-interactive: #0099bb,
      interactive: #0099bb,
      icon-primary: #0099bb,
      link-primary: #0099bb,
      focus: #0099bb,
      focus-inverse: #0099bb,
      //link-primary-hover: #294c86,
      //brand-01: #0099bb,
      //brand-02: #4d5358,
      //brand-03: #0099bb,
      background: #f4f4f4,
      background-inverse: map.get(themes.$g100,"background"),
      //layer-02: #e6e6e6,
      //hover-field: #d9d9d9,
      /// for example inside a table, if we hover the row

      background-hover: #d9d9d9,
      background-inverse-hover: map.get(themes.$g100,"background-hover"),
      //layer-hover-01: #d9d9d9,

      border-subtle-01: #393939,
      field-01: #f4f4f4,
      //text-secondary: #c6c6c6,
      //text-primary: #f4f4f4,
    )
  )
);

@use '@carbon/react/scss/components/button/tokens' with (
  $button-primary: theme.$background-brand,
);

// Or if you're wanting to use just the grid
@use '@carbon/react/scss/grid';

@use 'plex';

@use '@carbon/react';

@use 'website-nav';
@use 'tab-panels';
@use 'forms';
@use 'search';
@use 'datatable';

@use '@carbon/ibmdotcom-styles';

// Emit the flex-grid styles
@include grid.flex-grid();

//@debug "theme: #{theme.$theme}";
:root {
  @include theme.theme();
}


// modal Settinigs:
.toast_carbon {
  min-height: 1rem !important;
  padding: 0 !important;
  border: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
  width: auto !important;
}


// prevents jank while page hydrates
.body--preload * {
  transition: none !important;
}

.cds--content {
  //padding: 1rem;
  //background: transparent;
  > .cds--row {
    margin-right: 0;
  }
}
/*.cds--content {
  height: 100vh;
}*/

//---------------------------------------
// CSS reset overrides
//---------------------------------------
body em {
  font-style: italic;
}

body button {
  font-family: inherit;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
  max-height: 20rem;
  //height: auto;
}

// bx-label fix
label.cds--label {
  padding-top: 1rem;
}

legend.cds--label {
  font-size: 0.9rem;
  font-weight: 600;
}

/*.cds--modal-container {
  background-color: $layer-02;
  .cds--text-input {
    background-color: $field-02;
  }
  .cds--dropdown {
    background-color: $field-02;
  }
}*/


.custom_loading_container {
  flex: auto;
  flex-direction: column;
  gap: 2rem;
}

.custom_progress_bar_size {
  width: 70%;
}
